import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import MainPage from './pages/MainPage';

import './index.css';

const root = createRoot(
    document.getElementById("root")
);
root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
            <Route path="/">
                <Route index element={<MainPage />} />
                <Route path="home" element={<MainPage />} />
                <Route path="*" element={<MainPage />} />
            </Route>
        </Routes>
    </BrowserRouter>
);