import React from 'react';
import Section from './Section';
import styled from 'styled-components';

class WhatWeDo extends React.Component {
    render() {
        const Title = styled.h1`
            text-align: center;
        `;
        const Item = styled.div`
            line-height: 2.0em;
            background-color: #fff;
            margin: 10px;
        `;
        const Container = styled.div`
            display: grid;
            @media (min-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }
            grid-template-columns: repeat(1, 1fr);
        `;
        const Card = styled.div`
            text-align: center;
        `;
        const Description = styled.ul`
            @media (min-width: 1024px) {
                margin: 20px;
            }
            margin: 10px;
        `;
        return (
            <Section
                dark={true}
                id='section-what-we-do'
            >
                <Title>What We Do</Title>
                <Container>
                    <Item>
                        <Card>
                            <img src='assets/consultancy.webp' width={'100%'}></img>
                            <h2>IT CONSULTING</h2>
                        </Card>
                        <Description>
                            <li>Delivering transformative outcomes across all IT sectors.</li>
                            <li>Managing their implementation with a complete stack of consultancy services.</li>
                        </Description>
                    </Item>
                    <Item>
                        <Card>
                            <img src='assets/education.webp' width={'100%'}></img>
                            <h2>EDTECH</h2>
                        </Card>
                        <Description>
                            <li>Teaching the magic of IT on all knowledge levels.</li>
                            <li>Developing customized courses and learning curricula with personalized 1:1 mentorship.</li>
                        </Description>
                    </Item>
                    <Item>
                        <Card>
                            <img src='assets/software_development.webp' width={'100%'}></img>
                            <h2>SOFTWARE DEVELOPMENT</h2>
                        </Card>
                        <Description>
                            <li>Translating business tech problems into working solutions.</li>
                            <li>Performing long- and short-term quality and crisis management.</li>
                        </Description>
                    </Item>
                </Container>
            </Section>
        )
    }
}

export default WhatWeDo;