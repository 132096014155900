import React from 'react';
import styled from 'styled-components';

class Section extends React.Component {
    render() {
        const Content = styled.div`
            padding: 10px;
        `;
        const Wrapper = styled.div`
            background: ${this.props.dark ? '#eee' : 'inherit'};
            color: black;
        `

        return (
            <Wrapper>
                <Content id={this.props.id}>
                    {this.props.children}
                </Content>
            </Wrapper>
        );
    }
}

export default Section;