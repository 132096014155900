import React from 'react';
import Section from './Section';
import logo from './logo.svg';
import styled from 'styled-components';

class Home extends React.Component {
    render() {
        const Button = styled.button`
            background-color: #eee;
            font-weight: bolder;
            width: 180px;
            height: 50px;
            border: 1px solid black;
            margin-bottom: 50px;
            cursor: pointer;

            &:hover {
                color: blueviolet;
            }
        `;
        const Wrapper = styled.div`
            line-height: 2rem;
            text-align: center;
            display: grid;
            @media (min-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
            }
            grid-template-columns: repeat(1, 1fr);
        `;
        const Cube = styled.div`
            @media (min-width: 1024px) {
                padding: 50px;
            }
            padding: 10px;
        `;
        return (
            <Section
                dark={true}
                id='section-home'
            >
                <Wrapper>
                    <div>
                        <img src={logo} width='50%'></img>
                        <h1>Your partner in innovations.</h1>
                        <p>Business. Technology. Education.</p>
                        <Button onClick={() => {
                            document.getElementById('section-what-we-do').scrollIntoView({ behavior: 'smooth' });
                        }}>Find out more
                        </Button>
                    </div>
                    <Cube>
                        <img src='assets/golden_cube.webp' width={'80%'}></img>
                    </Cube>
                </Wrapper>
            </Section >
        )
    }
}

export default Home;