import React from 'react';
import Navbar from '../components/Navbar'
import Contact from '../components/Contact';
import Clients from '../components/Clients';
import WhatWeDo from '../components/WhatWeDo';
import WhoWeAre from '../components/WhoWeAre';
import Home from '../components/Home';

import './MainPage.css';

class MainPage extends React.Component {
    render() {
        return (
            <div>
                <Home />
                <WhoWeAre/>
                <WhatWeDo />
                <Clients />
                <Contact />
            </div>
        )
    }
};

export default MainPage;