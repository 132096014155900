import React from 'react';
import Section from './Section';
import logo from './logo-short.svg';
import styled from 'styled-components';

class Clients extends React.Component {
    render() {
        const Wrapper = styled.div`
            text-align: center;
            margin-bottom: 10px;
        `;
        const Clients = styled.div`
            display: grid;

            @media (min-width: 1024px) {
                padding: 50px;
                grid-template-columns: repeat(3, 1fr);
            }
            padding: 10px;
            min-height: 100px;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
            justify-items: center;
            align-items: center;
        `;
        return (
            <Section
                dark={false}
                id='section-clients'
            >
                <Wrapper>
                    <img src={logo} width='100'></img>
                    <h1>Success happens to those who work for it.</h1>
                    <Clients>
                        <img src='https://www.prodim-systems.nl/wp-content/uploads/sites/2/2018/02/Prodim-logo.png' width={'100%'}></img>
                        <img src='https://planting.space/img/logo_big_wide.svg' width={'100%'}></img>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Udacity_logo.svg/1280px-Udacity_logo.svg.png' width={'100%'}></img>
                    </Clients>
                    <em>
                        Meet some of our clients!
                    </em>
                </Wrapper>
            </Section>
        );
    }
}
export default Clients;
