import React from 'react';
import logo from './contacts-logo.svg'
import Section from './Section';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

class Contact extends React.Component {
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    render() {
        const Container = styled.div`
            line-height: 1.5rem;
            display: grid;
            column-gap: 20px;
            grid-template-columns: repeat(1, 1fr);
            margin: 20px;
            text-align: center;

            @media (min-width: 1024px) {
                grid-template-columns: 1fr 3fr 1fr 1fr;
            }
            `;
        const Logo = styled.div`
            margin: 20px;
            &:hover {
                cursor: pointer;
            }
        `;
        const Title = styled.h1`
            text-align: center;
        `;
        return (
            <Section
                dark={true}
                id='section-contact'
            >
                <Title>Let's do it together.</Title>
                <Container>
                    <Logo onClick={this.scrollToTop}>
                        <img src={logo} width='80%'></img>
                    </Logo>
                    <div>
                        <h3>BOOK A FREE INITIAL CONSULTATION</h3>
                        <ul style={{ textAlign: 'left' }}>
                            <li>
                                Send us your contact details and we will schedule an introductory, non-binding <b>half-an-hour</b> 1-on-1 ASAP.
                            </li>
                            <li>
                                It can be both an online call as well as an offline meeting in <b>Eindhoven</b> area.
                            </li>
                            <li>
                                Upon agreement, every online meeting is recorded and you get a <b>video</b> of it afterwards as well as all the <b>notes</b>.
                            </li>
                            <li>
                                <b>Let's meet half way!</b>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>CONTACT</h3>
                        <p>
                            <a href='tel:+31621833995'>+31 6 218-33-995</a>
                        </p>
                        <p>
                            <a href='mailto:hello@kond.eu'>hello@kond.eu</a>
                        </p>
                    </div>
                    <div>
                        <h3>KVK NUMMER</h3>
                        <p>
                            87132524
                        </p>
                    </div>
                </Container>
            </Section >
        )
    }
};

export default Contact;