import React from 'react';
import Section from './Section';
import styled from 'styled-components';

class WhoWeAre extends React.Component {
    render() {
        const Title = styled.h1`
            text-align: center;
        `;
        const Container = styled.div`
            display: grid;
            gap: 20px;
            @media (min-width: 1024px) {
                grid-template-columns: repeat(4, 1fr);
                padding: 20px;
            }
            grid-template-columns: repeat(1, 1fr);
            padding: 10px;
        `;
        const Item = styled.div`
            line-height: 2.0em;
            @media (min-width: 1024px) {
              padding: 20px;  
            }
            padding: 10px;
            align-self: center;
        `;
        const Caption = styled.div`
            text-align: center;
        `;
        return (
            <Section
                dark={false}
                id='section-who-we-are'
            >
                <Title>Who We Are</Title>
                <Container>
                    <Item>
                        <img src='assets/hanna.webp' width={'100%'}></img>
                        <Caption>Hanna</Caption>
                    </Item>
                    <Item>
                        <ul>
                            <li>
                                Experienced in EdTech, management, and consulting
                            </li>
                            <li>
                                Degrees in pedagogics, humanities, and computer science (ML&AI)
                            </li>
                            <li>
                                Passionate about helping others learn and grow
                            </li>
                        </ul>
                    </Item>
                    <Item>
                        <img src='assets/kirill.webp' width={'100%'}></img>
                        <Caption>Kirill</Caption>
                    </Item>
                    <Item>
                        <ul>
                            <li>
                                PhD. Computer Science
                            </li>
                            <li>
                                Senior Research Scientist
                            </li>
                            <li>
                                Technical lead with broad experience
                            </li>
                            <li>
                                Always striving for challenging impactful projects
                            </li>
                        </ul>
                    </Item>
                </Container>
            </Section>
        )
    }
}

export default WhoWeAre;