import React from 'react';
import logo from './logo.svg';
import { Link, animateScroll as scroll } from 'react-scroll';

export default class Navbar extends React.Component {
    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        return (
            <nav className="main-nav" id="main-navbar">
                <div className="main-nav-content">
                    <img
                        src={logo}
                        className="main-nav-logo"
                        alt="Logo"
                        onClick={this.scrollToTop}
                    />
                    <ul className="main-nav-items">
                        <li className="main-nav-item">
                            <Link
                                activeClass="active"
                                to="section-who-we-are"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Who We Are
                            </Link>
                        </li>
                        <li className="main-nav-item">
                            <Link
                                activeClass="active"
                                to="section-what-we-do"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                What We Do
                            </Link>
                        </li>
                        <li className="main-nav-item">
                            <Link
                                activeClass="active"
                                to="section-clients"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Our Clients
                            </Link>
                        </li>
                        <li className="main-nav-item">
                            <Link
                                activeClass="active"
                                to="section-contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}